import React, { useState } from "react";
import ProductAttributesModal from "../../../Products/components/ProductAttributesModal";
import { Button } from "../../../UI/components";
import { useMarketplaceProductState } from "../../hooks/useMarketplaceProductState";
import PartbotLogo from "/app/assets/images/partbot-logo.svg";

export const AttributesTab = () => {
  const {
    currentProduct,
    createMerchantProductAttribute,
    removeMerchantProductAttribute,
    currentProductLoading,
  } = useMarketplaceProductState();
  const [attributes, setAttributes] = useState(currentProduct?.attribute_values || []);
  const [attributesModalOpen, setAttributesModalOpen] = useState(false);
  const poweredByPartbot = currentProduct?.status === "Synced";

  const handleAddAttribute = async (value) => {
    setAttributesModalOpen(false);

    if (value) {
      const response = await createMerchantProductAttribute(currentProduct.id, value);

      if (response.status === 201) {
        setAttributes([...attributes, response.data]);
      }
    }
  };

  const handleRemoveAttribute = async (attributeId) => {
    const response = await removeMerchantProductAttribute(currentProduct.id, attributeId);

    if (response.status === 204) {
      setAttributes(attributes.filter((attribute) => attribute.id !== attributeId));
    }
  };

  return (
    <div className="">
      <div className="sm:flex sm:items-center">
        <div className="sm:flex-auto">
          <p className="text-xs text-gray-700">
            {poweredByPartbot
              ? "Manufacturer managed attributes cannot be changed."
              : "Add Partbot attributes to this product."}
          </p>
        </div>
        <div className="mt-4 sm:ml-16 sm:mt-0 sm:flex-none">
          {poweredByPartbot ? (
            <div className="flex space-x-1.5">
              <span className="text-xs text-gray-500">Powered by</span>
              <img src={PartbotLogo} className="h-4 w-auto" alt="Partbot" />
            </div>
          ) : (
            <>
              <Button
                label="Add Attribute"
                onClick={() => setAttributesModalOpen(true)}
                type="button"
                className="btn"
              />

              <ProductAttributesModal
                isOpen={attributesModalOpen}
                closeModal={handleAddAttribute}
              />
            </>
          )}
        </div>
      </div>
      <div className="mt-8 flex flex-col">
        <div className="-my-2">
          <div className="w-full py-2">
            <div className="overflow-clip rounded-lg border border-gray-200 shadow">
              <table className="w-full table-auto divide-y divide-gray-300 rounded-lg text-xs">
                <thead className="bg-gray-50">
                  <tr>
                    <th
                      scope="col"
                      className="py-2.5 pl-4 pr-3 text-left font-semibold text-gray-900 sm:pl-6"
                    >
                      Attribute
                    </th>
                    <th scope="col" className="px-3 py-2.5 text-left font-semibold text-gray-900">
                      Value
                    </th>
                    {!poweredByPartbot && (
                      <th scope="col" className="relative py-2.5 pl-3 pr-4 sm:pr-6">
                        <span className="sr-only">Remove</span>
                      </th>
                    )}
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-200 bg-white">
                  {attributes.length == 0 && (
                    <tr>
                      <td colSpan={3} className="px-6 py-2.5 text-gray-500">
                        No Attributes
                      </td>
                    </tr>
                  )}
                  {attributes
                    .filter((attribute) => attribute.id)
                    .map((attribute) => (
                      <tr key={attribute.id}>
                        <td className="py-2.5 pl-4 pr-3 font-medium text-gray-900 sm:pl-6">
                          {attribute.title}
                        </td>
                        <td className="max-w-sm truncate px-3 py-2.5 text-gray-500">
                          {attribute.value}
                        </td>
                        {!poweredByPartbot && (
                          <td className="relative py-2.5 pl-3 pr-4 text-right font-medium sm:pr-6">
                            <a
                              href="#"
                              className="text-danger-600 hover:text-danger-900"
                              onClick={() => handleRemoveAttribute(attribute.id)}
                            >
                              Remove<span className="sr-only">, {attribute.name}</span>
                            </a>
                          </td>
                        )}
                      </tr>
                    ))}
                  {currentProductLoading && (
                    <tr>
                      <td
                        colSpan="3"
                        className="py-2.5 pl-4 pr-3 text-center font-medium text-gray-400 sm:pl-6"
                      >
                        Loading attributes...
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
