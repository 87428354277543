import React from "react";
import { faPaperclip } from "@awesome.me/kit-989a8e6dbe/icons/classic/regular";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default function VariantDetail({ variant }) {
  return (
    <div className="w-full bg-white">
      <div className="text-xs">
        <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
          <div className="sm:col-span-1">
            <dt className="text-xs font-medium text-gray-500">Title</dt>
            <dd className="mt-1 text-xs text-gray-900">{variant.title}</dd>
          </div>
          <div className="sm:col-span-1">
            <div className="flex space-x-12">
              <div>
                <dt className="text-xs font-medium text-gray-500">RRP</dt>
                <dd className="mt-1 text-xs text-gray-900">
                  {new Intl.NumberFormat("en-US", {
                    style: "currency",
                    currency: "AUD",
                    currencyDisplay: "narrowSymbol",
                    minimumFractionDigits: 2,
                  }).format(variant.price)}
                </dd>
              </div>
              {variant.cost && (
                <div>
                  <dt className="text-xs font-medium text-gray-500">Cost</dt>
                  <dd className="mt-1 text-xs text-gray-900">
                    {new Intl.NumberFormat("en-US", {
                      style: "currency",
                      currency: "AUD",
                      currencyDisplay: "narrowSymbol",
                      minimumFractionDigits: 2,
                    }).format(variant.cost)}
                  </dd>
                </div>
              )}
            </div>
          </div>
          <div className="sm:col-span-1">
            <dt className="text-xs font-medium text-gray-500">Product Type</dt>
            <dd className="mt-1 text-xs text-gray-900">{variant.product_type}</dd>
          </div>
          <div className="sm:col-span-1">
            <dt className="text-xs font-medium text-gray-500">Packaging</dt>
            <dd className="mt-1 space-y-1.5 text-xs text-gray-900">
              {variant?.packages.map((p) => {
                return (
                  <div key={p.id}>
                    L: {p.length}cm, W: {p.width}cm, H: {p.height}cm ({p.weight}kg)
                  </div>
                );
              })}
            </dd>
          </div>
          <div className="sm:col-span-2">
            <dt className="text-xs font-medium text-gray-500">Specifications</dt>
            <dd className="mt-1 text-xs text-gray-900">
              <ul className="space-y-2">
                {Object.entries(variant.attributes).map(([k, v]) => {
                  return (
                    <li key={k}>
                      <span className="font-bold">{k}:</span>{" "}
                      <span className="text-indigo-500">{v}</span>
                    </li>
                  );
                })}
              </ul>
            </dd>
          </div>

          {variant.vehicle_applications.length ? (
            <div className="sm:col-span-2">
              <dt className="text-xs font-medium text-gray-500">Vehicle Applications</dt>
              <dd className="mt-1 text-xs text-gray-900">
                <table className="text-xs">
                  <thead>
                    <tr>
                      <th>Make</th>
                      <th>Model</th>
                      <th>From</th>
                      <th>To</th>
                      <th>Body</th>
                      <th>Series</th>
                      <th>Engine</th>
                      <th>Variant</th>
                      <th>Drivetrain</th>
                    </tr>
                  </thead>

                  {variant.vehicle_applications.map((application, index) => {
                    return (
                      <tbody key={application.id}>
                        {application.vehicles.map((vehicle) => {
                          return (
                            <tr key={vehicle.id}>
                              <td>{vehicle.make}</td>
                              <td>{vehicle.model}</td>
                              <td>{vehicle.from}</td>
                              <td>{vehicle.to}</td>
                              <td>{vehicle.body}</td>
                              <td>{vehicle.series}</td>
                              <td>{vehicle.engine}</td>
                              <td>{vehicle.variant}</td>
                              <td>{vehicle.drivetrain}</td>
                            </tr>
                          );
                        })}
                      </tbody>
                    );
                  })}
                </table>
              </dd>
            </div>
          ) : (
            ""
          )}
          {variant.document ? (
            <div className="sm:col-span-2">
              <dt className="text-xs font-medium text-gray-500">Documents</dt>
              <dd className="mt-1 text-xs text-gray-900">
                <ul
                  role="list"
                  className="divide-y divide-gray-200 rounded-md border border-gray-200"
                >
                  <li className="flex items-center justify-between py-3 pl-3 pr-4 text-xs">
                    <div className="flex w-0 flex-1 items-center">
                      <FontAwesomeIcon
                        icon={faPaperclip}
                        className="h-3 w-3 flex-shrink-0 text-gray-400"
                      />
                      <span className="ml-2 w-0 flex-1 truncate">{variant.document}</span>
                    </div>
                    <div className="ml-4 flex-shrink-0">
                      <a
                        href={variant.document}
                        target="_blank"
                        download
                        className="font-medium text-indigo-600 hover:text-indigo-500"
                      >
                        Download
                      </a>
                    </div>
                  </li>
                </ul>
              </dd>
            </div>
          ) : (
            ""
          )}
        </dl>
      </div>
    </div>
  );
}
